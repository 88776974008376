<template>
  <div class="product">
      <banner
          banner1="https://n.tobidad.com/site/videos/tools/banner1.mp4?v=1.0.1"
          banner2="https://n.tobidad.com/site/videos/tools/banner2.mp4?v=1.0.1"
          text="高效智能的聚合广告工具"
          style="height: 500px;"
      />

      <div class="section section1">
        <h2>丰富多样的瀑布流模式</h2>
        <div class="sticky-content" style="--40cf1ef0: 120px; --40b3d164: 50px; --5b65dc39: 770px; --10f29dd8: 550px;">
          <div class="sticky-wrapper">
            <div class="anchor">
              <div class="anchor-item active">
                <img src="https://n.tobidad.com/site/images/tools/icon_hb.png?v=1.0.0" alt="">
                <span>应用内竞价</span>
              </div>
              <div class="anchor-item">
                <img src="https://n.tobidad.com/site/images/tools/icon_layer.png?v=1.0.0" alt="">
                <span>广告分层</span>
              </div>
              <div class="anchor-item">
                <img src="https://n.tobidad.com/site/images/tools/icon_waterfall.png?v=1.0.0" alt="">
                <span>混合瀑布流</span>
              </div>
            </div>
            <div class="video-wrapper">
              <video src="https://n.tobidad.com/site/videos/tools/hb.mp4" poster="" preload="auto" loop="" autoplay muted playsinline="" class=""></video>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="video-text-box">
              <h3> 应用内<span style="color: rgb(17, 170, 248);">竞价</span></h3>
              <p> 一次曝光机会将公开向所有应用内竞价广告平台发起竞价<br> 会选取出价最高的广告进行展示<br>确保开发者收益最大 </p>
              <video src="https://n.tobidad.com/site/videos/tools/hb.mp4" poster="" preload="auto" autoplay muted playsinline=""></video>
            </div>
            <div class="video-text-box">
              <h3> Waterfall<span style="color: rgb(249, 204, 106);">广告分层</span></h3>
              <p> 对广告平台依照历史表现设置eCPM底价分层<br>确保优质广告源得到充分的曝光机会 </p>
              <video src="https://n.tobidad.com/site/videos/tools/layer.mp4" poster="" preload="auto" autoplay muted playsinline=""></video>
            </div>
            <div class="video-text-box">
              <h3> 智能<span style="color: rgb(176, 216, 93);">混合瀑布流</span></h3>
              <p> 混合瀑布流模式融合传统瀑布流和应用内竞价的优势<br>开发者可结合应用特点灵活设置瀑布流 </p>
              <video src="https://n.tobidad.com/site/videos/tools/waterfall.mp4" poster="" preload="auto" autoplay muted playsinline=""></video>
            </div>
          </div>
        </div>
      </div>

      <div class="section section2">
        <h2>高效的请求策略</h2>
        <h4>在实际的变现业务中，每个变现场景有其独特性。场景的独特性会影响到开发者的变现效率<br>因此开发者需要根据场景的独特性设计瀑布流的请求、加载模式</h4>
        <div class="sticky-content reverse" style="--40cf1ef0: 120px; --40b3d164: 50px; --5b65dc39: 770px; --10f29dd8: 550px;">
          <div class="sticky-wrapper">
            <div class="anchor">
              <div class="anchor-item active">
                <img src="https://n.tobidad.com/site/images/tools/icon_fixed.png" alt="">
                <span>固定并行组</span>
              </div>
              <div class="anchor-item">
                <img src="https://n.tobidad.com/site/images/tools/icon_equal.png" alt="">
                <span>按价格并行请求</span>
              </div>
              <div class="anchor-item">
                <img src="https://n.tobidad.com/site/images/tools/icon_substitute.png" alt="">
                <span>固定并行补位请求</span>
              </div>
            </div>
            <div class="video-wrapper">
              <video src="https://n.tobidad.com/site/videos/tools/fixed.mp4?v=1.0.1" poster="" preload="auto" loop="" autoplay muted playsinline="" class=""></video>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="video-text-box">
              <h3><span style="color: rgb(120, 85, 194);">固定数量并行</span>请求 </h3>
              <p> 对于一些预估eCPM较高的广告源<br>开发者可以手动将其设置为同一个并行组<br>确保当前广告时机内曝光收益最大化 </p>
              <video src="https://n.tobidad.com/site/videos/tools/fixed.mp4?v=1.0.1" poster="" preload="auto" autoplay muted playsinline=""></video>
            </div>
            <div class="video-text-box">
              <h3><span style="color: rgb(17, 170, 248);">相同价格并行</span>请求 </h3>
              <p> 在相同价格区间内设置多个广告源进行并行请求<br>可提高价值广告源的曝光机会 </p>
              <video src="https://n.tobidad.com/site/videos/tools/equal.mp4?v=1.0.1" poster="" preload="auto" autoplay muted playsinline=""></video>
            </div>
            <div class="video-text-box">
              <h3><span style="color: rgb(249, 204, 106);">固定数量并行补位</span>请求 </h3>
              <p> 在一些广告场景（如开屏）中<br>开发者需在有限时间内同时保证快速的广告填充+曝光的高收益<br> 推出的固定并行数量补位请求模式相对传统并行请求<br>在保证曝光收益的情况下，能够大幅提升填充速度 </p>
              <video src="https://n.tobidad.com/site/videos/tools/substitute.mp4?v=1.0.1" poster="" preload="auto" autoplay muted playsinline=""></video>
            </div>
          </div>
        </div>
      </div>

      <div class="section section3">
        <div class="content">
          <video class="video-block" src="https://n.tobidad.com/site/videos/tools/group.mp4" poster="" preload="auto" loop autoplay muted playsinline=""></video>
          <div class="wrapper">
            <h2>多维流量分组</h2>
            <div class="title-line">
            </div>
            <h4>相同特征、标签的用户往往存在共同偏好<br> 支持将用户进行地区、年龄、设备类型等群组划分<br>投其所好助力精细化运营</h4>
          </div>
        </div>
      </div>

      <div class="section section4">
        <div class="content">
          <div class="wrapper">
            <h2>A/B Test</h2><div class="title-line"></div>
            <h4>善用 A/B Test，对用户随机分组<br>验证不同变现策略，快速提升变现收益</h4>
          </div>
          <video class="video-block" src="https://n.tobidad.com/site/videos/tools/ab_test.mp4" poster="" preload="auto" loop autoplay muted playsinline=""></video>
        </div>
      </div>

  </div>
</template>

<script>
import Banner from "@/components/Card/Banner";
export default {
  name: "Product",
  components: {Banner}
}
</script>

<style scoped>
.sticky-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sticky-content.reverse {
  flex-flow: row-reverse;
}

.sticky-content.reverse .content-wrapper {
  margin: 0px 60px 0px 0px;
}

.sticky-content .sticky-wrapper {
  position: sticky;
  top: var(--40cf1ef0);
}

.sticky-content .sticky-wrapper .anchor {
  display: flex;
  height: var(--40b3d164);
}

.sticky-content .sticky-wrapper .anchor .anchor-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  opacity: 0.5;
  transition: all 0.5s ease 0s;
  cursor: pointer;
}

.sticky-content .sticky-wrapper .anchor .anchor-item.active {
  opacity: 1;
  font-weight: 500;
}

.sticky-content .sticky-wrapper .anchor .anchor-item img {
  height: 24px;
}

.sticky-content .sticky-wrapper .anchor .anchor-item span {
  margin: 0px 8px;
}

.sticky-content .sticky-wrapper .video-wrapper {
  position: relative;
  width: var(--5b65dc39);
  height: var(--10f29dd8);
}

.sticky-content .sticky-wrapper .video-wrapper video {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.sticky-content .content-wrapper {
  margin: 0px 0px 0px 60px;
  padding-top: var(--40b3d164);
}

.sticky-content .content-wrapper video{
  display: block;
  width: 0px;
  height: 0px;
  visibility: hidden;
}

.sticky-content .content-wrapper .video-text-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
  height: var(--10f29dd8);
}

.product {
  min-width: 1440px;
}

.section {
  display: block;
}

.section h2{
  font-size: 36px;
}

.section h3 {
  font-size: 28px;
  margin: 0px 0px 10px;
}

.section h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 10px;
}

.section p {
  font-size: 15px;
  line-height: 1.5;
}

.section:nth-child(2n+1) {
  background-color: rgb(250, 250, 250);
}

.section .wrapper {
  display: inline-block;
  text-align: left;
}

.section .video-block {
  width: 600px;
}

.section .title-line {
  background-image: linear-gradient(to right, rgb(36, 220, 255) 0%, rgb(21, 173, 255) 100%);
}

.sticky-content {
  margin-top: 60px;
}

.section1, .section2 {
  padding: 80px 0px;
  overflow: visible;
  max-height: none;
  height: auto !important;
}

.section1 h2, .section2 h2, .section1 h4, .section2 h4 {
  text-align: center;
}

.section3, .section4 {
  height: 700px;
}

.section3 h2, .section4 h2 {
  font-size: 30px;
  margin-bottom: 10px;
}

.section3 h4, .section4 h4 {
  margin: 20px 0px;
}

.section3 > .content, .section4 > .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.section3 > .content .video-block + .wrapper, .section4 > .content .video-block + .wrapper, .section3 > .content .wrapper + .video-block, .section4 > .content .wrapper + .video-block {
  margin-left: 10%;
}

.section4 .video-block {
  width: 520px;
}

</style>
